<template>
  <div class="shop-container">
    <iframe :src="url" frameborder="0" style="width:100%;height:100vh" @load="iframeLoad"></iframe>
  </div>
</template>
<script>
export default {

  data() {
    return {
      userToken: '',
      url: ' '
    }
  },
  methods: {
    iframeLoad() {
      console.log('loadsuccess');
      window.addEventListener("message",this.hanldeAcceptMessage, true);
    },
    hanldeAcceptMessage(e) {
      if(e.origin === "https://store.amazingmemo.com" && e.data.act === "toStudy" && e.data.chapterId) {
        location.href = '/home/studyPlatform/chapter/' + e.data.chapterId;
      }
    }
  },
  deactivated() {
    console.log('移除');
    window.removeEventListener("message",this.hanldeAcceptMessage, true);
  },
  created() {
    if (this.$cookies.get('authToken')) {
      this.userToken = this.$cookies.get('authToken')
      this.url = "https://store.amazingmemo.com?token=" + this.userToken
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-container {
  padding: 0 26px;
}
</style>

